import React from 'react'
import Breadcumb from './components/Breadcumb'
import Details from './components/Details'

const App = () => {
  return (
    <div className='container'>
      <Breadcumb />
      <Details />
    </div>
  )
}

export default App
