import React from 'react';
import '../styles/Breadcumb.css';

const Breadcumb = () => {
  return (
    <nav className="nav-links">
      <a href="#!">Home</a> {'>'} <a href="#!">Laptop</a>
    </nav>
  )
}

export default Breadcumb